// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/opt/build/repo/src/pages/aboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-experience-js": () => import("/opt/build/repo/src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-hobbies-js": () => import("/opt/build/repo/src/pages/hobbies.js" /* webpackChunkName: "component---src-pages-hobbies-js" */),
  "component---src-pages-home-js": () => import("/opt/build/repo/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-skills-js": () => import("/opt/build/repo/src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */)
}

